import { z } from "zod";
import { EffectType } from "../constants";
import { zNumericId } from "./baseschemas";
import { zCurrencyDto } from "./currencies";
import { zGenerationUpdateDto } from "./generations";

const zEffectCategoryValueDto = z.object({
    id: zNumericId,
    value: zNumericId,
    effectCategoryAttributeId: zNumericId,
});

export const zEffectCategoryDto = z.object({
    id: zNumericId,
    measureId: zNumericId,
    effectType: z.nativeEnum(EffectType),
    inputFields: z.record(z.string(), z.any()),
    effectCategoryValues: z.array(zEffectCategoryValueDto),
    currencyId: zNumericId,
    currency: zCurrencyDto,
});

export type EffectCategoryDto = z.infer<typeof zEffectCategoryDto>;

export const zEffectCategoryListDto = z.array(zEffectCategoryDto);

export type EffectCategoryListDto = z.infer<typeof zEffectCategoryListDto>;

export const zEffectCategoryFields = z.record(z.number().optional());

export type EffectCategoryFields = z.infer<typeof zEffectCategoryFields>;

export const zCreateEffectCategoryDto = z.object({
    categoryFields: zEffectCategoryFields,
    calculationFields: zGenerationUpdateDto.optional(),
    effectType: z.nativeEnum(EffectType),
    currencyId: zNumericId,
});

export type CreateEffectCategoryDto = z.infer<typeof zCreateEffectCategoryDto>;

export const zUpdateEffectCategoryDto = z.object({
    categoryFields: zEffectCategoryFields.optional(),
    calculationFields: zGenerationUpdateDto.optional(),
    // effectType should not be updatable
    currencyId: zNumericId.optional(),
});

export type UpdateEffectCategoryDto = z.infer<typeof zUpdateEffectCategoryDto>;
